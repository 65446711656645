
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

//paginas generales----------
import Home from './pages/generalpages/Home';
import Blog from './pages/generalpages/Blog';
import BlogDetail from './pages/generalpages/BlogDetail';
import Conocenos from './pages/generalpages/Conocenos';

//paginas de sesiones
import RecuperarContrasena from './pages/sesiones/RecuperarContrasena';
import Register from './pages/sesiones/Register';
import Validate from './pages/sesiones/validarCodigo';
import ResetPassword from './pages/sesiones/changepassword'
import ActivateAccount from './pages/sesiones/ActivateAccount';
import Login from './pages/sesiones/Login';

//pagina de usuarios---------
import Adrees from './pages/user/perfil/Adrees';
import CarStore from './pages/user/compras/CarStore';
import PerfilUser from './pages/user/perfil/PerfilUser';
import Pedidos from './pages/user/pedidos/Pedidos';
import Opiniones from './pages/user/opiniones/Opiniones';
import OpinarEspecifico from './pages/user/opiniones/OpiarEspecifico';
import Favoritos from './pages/user/favoritos/Favoritos';

//productoos---------
import Product from './pages/productos/produtosinfo/Product';
import Products from './pages/productos/produtosinfo/Products';
import Categories from './pages/productos/categorias/Categories';
import CategoriesSpecial from './pages/productos/categorias/CategoriesSpecial';
import Comparation from './pages/productos/produtosinfo/Camparation';
import AllCategories from './pages/productos/categorias/AllCategories';

//protected route
import { ProtectedRouteSession, ProtectedRoute, ProtectedInterfazAdmi } from './pages/protectedruta/ProtectedRoute';

//paginas pagoos -*-*-*-*-*-*
import Pagos from './pages/pagos/Pago';
import Cargapago from './pages/pagos/Cargapago';
import DetalleDelPedido from './pages/pagos/Detallepedido';
import Listametodospagotxt from './pages/pagos/Listametodospagotxt';
import PagoOxxo from './pages/pagos/PagoOxxo';
import PagoTranferencia from './pages/pagos/PagoTranferencia';
import TransferenciaStripe from './pages/pagos/TransferenciaStripe';
import AddTarjetaCB from './pages/pagos/PagoTarjeta';


//soporte pages
import Contact from './pages/Soporte/Contact';
import Soporte from './pages/Soporte/Soporte';
import Faqs from './pages/Soporte/FAQ';
import Politicas from './pages/Soporte/Politicas';

//politicas de cookies
import ConsentimientoCookies from './componentes/cookies/ConsentimientoCookies ';

//paginas de errores
import ErrorPage from './pages/erorpage/ErrorPage';

//paginas de adrminastrador
import Dashboard from './pages/admi/Dashboard';
import ProductsManagment from './pages/admi/ProductsManagment';
import AddProduct from './pages/admi/AddProduct';
import Orders from './pages/admi/Orders';
import AddPedido from './pages/admi/AddPedido';
import QuestionProduct from './pages/admi/QuestionProduct';
import AddCuestion from './pages/admi/AddCuestion';
import BannersPage from './pages/admi/BannersPage';
import CategoriesPage from './pages/admi/CategoriesPage';
import IndividualCategoria from './pages/admi/IndividualCategoria ';
import ContactarClientes from './pages/admi/ContactarClientes';
import DetallesContacto from './pages/admi/DetallesContacto';
import AllProducts from './pages/productos/categorias/AllProducts';
import GarantiasPage from './pages/generalpages/Garantiaspage';
import Sooprte from './pages/Soporte/Soporte';
import { SnipViewsPerHour, useFirstVisitOfDay, usePageLittleTime, usePageTime } from './utils/Analitycs/analytics';
import Clientes from './pages/admi/Clientes';
import AddCombo from './pages/admi/AddCombo';
import ListCombos from './pages/productos/combos/ListCombos';
import IndividualCombo from './pages/productos/combos/IndividualCombo';
import TagSelecct from './pages/productos/tags/TagSelecct';
import CentroServicio from './pages/Soporte/CentroServicio';
import Capacitaicones from './pages/Soporte/Capacitaicones';
import Garantia from './pages/Soporte/Garantia';
import OtroMetodoPago from './pages/pagos/OtroMetodoPago';
import LoginAutoZone from './pages/sesiones/LoginAutoZone';
import AddDistribuidor from './componentes/Admi/AddDistribuidor';
import ResultsBySearch from './pages/productos/resultSearch/ResultsBySearch';
import PoliticasPrivacidad from './pages/Soporte/PoliticasPrivacidad';
import PromosCupones from './pages/admi/Promociones';
import AddCupon from './pages/admi/AddCupon';
import ViewsLinks from './pages/viewLinks/ViewsLinks';
import LandingCompressor from './pages/landing_products/LandingCompressor';


function App() {


  SnipViewsPerHour()
  usePageTime()
  usePageLittleTime()
  useFirstVisitOfDay()


  return (
    <div className="App">
      <Router>
        <ConsentimientoCookies />

        <Routes>

          {/* Ruta principal */}
          <Route path="/" element={<Home />} />

          <Route path="/collections/levante-1" element={<Navigate to="/Categorias/Rampas" />} />

          <Route path="/pages/promociones" element={<Navigate to="/CategoriasEspeciales/ofertas_temporada" />} />
          <Route path="/collections/mas-vendidos" element={<Navigate to="/CategoriasEspeciales/mas_vendidos" />} />
          <Route path="/pages/promociones" element={<Navigate to="/CategoriasEspeciales/ofertas_temporada" />} />
          <Route path="/collections/all" element={<Navigate to="/allProducts" />} />

          <Route path="/collections/desmontadoras-1" element={<Navigate to="/Categorias/Desmontadora" />} />

          <Route path="/categorias-contacto" element={<ViewsLinks />} />


          {/* Ruta 404 */}
          <Route path="*" element={<Home />} />
          {/* <Route path="*" element={<ErrorPage />} /> */}

          {/* Rutas no disponibles cuando ha iniciado sesión */}
          <Route element={<ProtectedRouteSession toRoute={'/'} />} >
            <Route path="/registermodule" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/Login-Distribuidor" element={<LoginAutoZone />} />


          </Route>
          <Route path="/responsePayment" element={<Cargapago />} />

          {/* Rutan protegidas cuando ha iniciado sesión */}
          <Route element={<ProtectedRoute />}>
            <Route path="/Mi-Perfil" element={<PerfilUser />} />
            <Route path="/Pedidos" element={<Pedidos />} />
            <Route path="/Pago-Tranferencia/:idPago" element={<TransferenciaStripe />} />
            <Route path="/Pago-Oxxo/:idPago" element={<PagoOxxo />} />
            {/* <Route path="/Cargapago" element={<Cargapago />} /> */}
            {/* cargapago */}
            <Route path="/otro-Metodo-de-Pago/:idPedido" element={<OtroMetodoPago />} />

            <Route path="/Detalle-Del-Pedido/:idpedido" element={<DetalleDelPedido />} />
            <Route path="/pago-tarjeta" element={<AddTarjetaCB />} />
            <Route path='/Favoritos' element={<Favoritos />} />

          </Route>
          <Route path="/Opiniones" element={<Opiniones />} />
          <Route path="/opinarEspecifico" element={<OpinarEspecifico />} />


          {/* Admi */}
          <Route element={<ProtectedInterfazAdmi />} >
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/products_managment' element={<ProductsManagment />} />
            <Route path='/addProduct' element={<AddProduct />} />
            <Route path='/addPedido' element={<AddPedido />} />
            <Route path='/list_orders' element={<Orders />} />
            <Route path='/BannersPage' element={<BannersPage />} />
            <Route path='/BannersPage' element={<BannersPage />} />
            <Route path='/questionProduct' element={<QuestionProduct />} />
            <Route path='/addCuestion' element={<AddCuestion />} />
            <Route path='/contact_client' element={<ContactarClientes />} />
            <Route path="/detallescontacto" element={<DetallesContacto />} />
            <Route path="/lista-Clientes" element={<Clientes />} />
            <Route path="/add-combo" element={<AddCombo />} />
            <Route path='/CategoriesPage' element={<CategoriesPage />} />
            <Route path='/IndividualCategoria' element={<IndividualCategoria />} />
            <Route path='/agregar-distribuidor' element={<AddDistribuidor />} />
            <Route path="/comparation" element={<Comparation />} />
            <Route path="/add-cupon" element={<AddCupon />} />



            <Route path="/promociones-cupones" element={<PromosCupones />} />





          </Route>



          {/* Rutas generales */}

          <Route path="/recuperarcontrasena" element={<RecuperarContrasena />} />
          <Route path="/validatemodule/:email" element={<Validate />} />
          <Route path="/resetpassword/:email" element={<ResetPassword />} />
          <Route path="/activateAccount/:email/:act_two" element={<ActivateAccount />} />
          <Route path="/allProducts" element={<AllProducts />} />
          <Route path="/garantias" element={<GarantiasPage />} />
          <Route path="/soporte" element={<Sooprte />} />
          <Route path="/combos" element={<ListCombos />} />
          <Route path="/etiquetas/:titulo" element={<TagSelecct />} />

          <Route path="/combonum/:id" element={<IndividualCombo />} />
          <Route path="/search-results" element={<ResultsBySearch />} />



          <Route path="/adrees" element={<Adrees />} />
          <Route path="/car-store" element={<CarStore />} />

          {/* Ruta para el producto a partir de su ID */}
          <Route path="/product/:product_name/:id_product" element={<Product />} />
          <Route path="/products/*" element={<Products />} />
          <Route path="/NuestrasCategorias" element={<AllCategories />} />

          <Route path="/Categorias/:id" element={<Categories />} />
          <Route path="/CategoriasEspeciales/:id" element={<CategoriesSpecial />} />
          <Route path="/Pagos" element={<Pagos />} />



          <Route path="/Contact" element={<Contact />} />
          <Route path="/Conocenos" element={<Conocenos />} />
          <Route path="/terminos-y-condiciones/:id?" element={<Faqs />} />
          <Route path="/aviso-de-privacidad" element={<PoliticasPrivacidad />} />

          <Route path="/Politicas-Privacidad" element={<Politicas />} />
          <Route path="/Listametodospagotxt" element={<Listametodospagotxt />} />
          <Route path="/Blog" element={<Blog />} />

          <Route path="/centro-de-servicio" element={<CentroServicio />} />
          <Route path="/capacitaciones" element={<Capacitaicones />} />
          <Route path="/garantia" element={<Garantia />} />



          <Route path="/force_element" element={<LandingCompressor />} />

          <Route path="/BlogDetail" element={<BlogDetail />} />

          {/* <Route path="/Pago-Tranferencia/:idPago" element={<PagoTranferencia />} /> */}

        </Routes>
      </Router>
    </div>
  );
}

export default App;
